import dashboard from './dashboard';
import account from './account';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, account]
};

export default menuItems;
