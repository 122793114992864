/* eslint-disable import/prefer-default-export */
const reddit = 'platforms/reddit.png';
const linkedin = 'platforms/linkedin.png';
const quora = 'platforms/quora.png';
const twitter = 'platforms/twitter.png';
const facebook = 'platforms/facebook.png';
const instagram = 'platforms/instagram.png';
const pinterest = 'platforms/pinterest.png';
const tiktok = 'platforms/tiktok.png';

export const platformsSrc = {
    'reddit.com': reddit,
    'linkedin.com': linkedin,
    'quora.com': quora,
    'twitter.com': twitter,
    'facebook.com': facebook,
    'instagram.com': instagram,
    'pinterest.com': pinterest,
    'tiktok.com': tiktok
};

export const keywordColors = {
    1: {
        background: '#C7FCEB',
        color: '#0A362E'
    },
    2: {
        background: '#CDF2FA',
        color: '#0F3A4D'
    },
    3: {
        background: '#FFF1CD',
        color: '#3B2109'
    }
};
